/*	   typography   	*/

$body-font: 'Rubik', arial, helvetica, sans-serif;
$title-font: 'poppins', arial, helvetica, sans-serif;
$rear-title-font: 'din_condensed', arial, helvetica, sans-serif;

$fs-body: 14px;
$lh-body: 20px;

$fs-h1: 56px;
$lh-h1: 60px;

$fs-h2: 100px;

$fs-medium: 20px;
$lh-medium: 28px;

$fs-small-title: 16px;

/*	   end  typography   	*/


/*	   color   	*/

$text-color: #333;
$white-color: #fff;
$error-color: #B00020;
$rear-title-color: rgba(38,71,83,.05);

/*	   end  color   	*/


/*	   background   	*/

$body-bg: #fff;
$black-bg: #232323;
$overlay-bg: rgba(0,0,0,0.8);
$light-gray-bg: #F9F9F9;

/*	   end  background   	*/