@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-Light.eot');
    src: url('../fonts/Rubik-Light.woff') format('woff'),
         url('../fonts/Rubik-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-LightItalic.eot');
    src: url('../fonts/Rubik-LightItalic.woff') format('woff'),
         url('../fonts/Rubik-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-Regular.eot');
    src: url('../fonts/Rubik-Regular.woff') format('woff'),
         url('../fonts/Rubik-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-Medium.eot');
    src: url('../fonts/Rubik-Medium.woff') format('woff'),
         url('../fonts/Rubik-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'din_condensed';
    src: url('../fonts/din_condensed_bold-webfont.woff2') format('woff2'),
         url('../fonts/din_condensed_bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'poppins';
    src: url('../fonts/poppins-medium-webfont.woff2') format('woff2'),
         url('../fonts/poppins-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'poppins';
    src: url('../fonts/poppins-bold-webfont.woff2') format('woff2'),
         url('../fonts/poppins-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
