@import 'variables.scss';


.tab {

	&.hidden{
		position: absolute;
		left: -99999px;
		top: -99999px;
	}
	&.visible {
		position: static;
	}
}
.tabs-box {
	overflow: hidden;
}
.tabs-menu {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	font-weight: normal;
	white-space: nowrap;
	margin: 0 15px 10px;

	li {
		border-radius: 2px;
		padding: 9px 20px;
		margin: 0 5px 10px;
		cursor: pointer;
		transition: all .1s ease-in-out;

		&.active,
		&:hover {
			color: $white-color;
			background-color: $black-bg;
		}
		&.active {cursor: default;}
	}
}