@import 'variables.scss';


/*	   footer   	*/
#footer {
	width: 100%;
	background-color: $black-bg;
	overflow: hidden;
	font-size: 12px;
	color: $white-color;
	padding-top: 50px;

	.logo {
		width: 60px;
	}
	h3 {
		font-size: 12px;
		font-weight: normal;
		margin: 0 0 10px;
	}
	a {
		color: $white-color;
		opacity: .6;
		transition: opacity .1s ease-in-out;

		&:hover {
			opacity: 1;
			text-decoration: none;
		}
	}
	.socials-links a {
		opacity: .88;

		&:hover {opacity: 1;}

		&:first-of-type {
			margin-left: 0;
		}
	}
	.footer-menu li {margin: 0 0 10px;}
}
.footer-hold {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;
	margin: 0 0 10px;
}
.bottom-footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	padding: 20px 0;

	&:before {
		content: '';
		background-color: $light-gray-bg;
		height: 1px;
		width: 100vw;
		opacity: .2;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
	}
	.copy {opacity: .6;}
}