@import 'variables.scss';

@media only screen and (max-width: 1340px) {
    .modal-window .close-modal {
        right: 0;
    }
}
@media only screen and (max-width: 1150px) {
    .modal-window {
        width: 100%;
    }
}

@media only screen and (max-width: 1023px) {
    html.hidden,
    body.hidden {
        overflow: hidden;
    }

    #nav {
        order: 1;
        width: 33.33%;
    }
    .header-hold {
        .logo {
            order: 2;
        }
        .socials-links {
            order: 3;
            width: 33.33%;
            justify-content: flex-end;
        }
    }

    /*     navigation       */
    .toogle-menu,
    .close-btn {
        display: block;
    }

    .menu-holder {
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow-y: auto;
        overflow-x: hidden;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 100px 0;
        z-index: 100;
        opacity: 0;
        visibility: hidden;
        transform: scaleY(0.5);
        transition: all 0.3s ease-in-out;
        box-sizing: border-box;
        background: $black-bg;
    }
    input[id='check-menu']:checked ~ .header-hold {
        .menu-holder {
            opacity: 1;
            visibility: visible;
            transform: scaleY(1);
        }
        .toogle-menu {
            background: url(../images/Close.svg) 50% 50% no-repeat;
            background-size: 65%;
        }
        .logo {
            .black-logo {
                display: none;
            }

            .white-logo {
                display: block;
            }
        }
        .socials-links {
            /*.twitter-link {
                background-image: url(../images/Twitter-white.svg);
            }*/
            .instagram-link {
                background-image: url(../images/Instagram-white.svg);
            }
            .facebook-link {
                background-image: url(../images/Facebook-white.svg);
            }
        }
    }
    .menu {
        display: block;
        text-align: center;

        > li {
            margin: 0 0 26px;

            &:last-of-type {
                margin: 0;
            }

            > a {
                display: block;
                color: $white-color;
                padding: 7px 10px;

                &:before {
                    content: '';
                    height: 34px;
                    background-position: 50% 50%;
                    background-repeat: no-repeat;
                    background-size: contain;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: -1;
                    opacity: 0;
                    transition: opacity 0.2s ease-in-out;
                }
            }
            &:hover a {
                text-decoration: none;
            }
            &.active-item > a {
                color: $black-bg;
                text-decoration: none;

                &:before {
                    opacity: 1;
                }
            }
            &.home-item > a:before {
                width: 155px;
                background-image: url(../images/brush-blue.svg);
            }
            &.about-me-item > a:before {
                width: 189px;
                background-image: url(../images/brush-purple.svg);
            }
            &.projects-item > a:before {
                width: 178px;
                background-image: url(../images/brush-orange.svg);
            }
            &.reviews-item > a:before {
                width: 178px;
                background-image: url(../images/brush-terracotta.svg);
            }
            &.request-quote-item > a:before {
                width: 255px;
                background-image: url(../images/brush-yellow.svg);
            }
            &.contact-item > a:before {
                width: 175px;
                background-image: url(../images/brush-green.svg);
            }
        }
    }
    .menu-contacts {
        display: flex;
    }
    /*     end navigation       */

    h1 {
        font-size: 40px;
        line-height: 50px;
    }
    h2 {
        font-size: 60px;
        line-height: 90px;
        margin: 0 0 20px;
    }
    .title-box {
        .title-col {
            width: 298px/728px * 100%;
            font-size: $fs-body;
            padding: 20px 20px 0 0;
        }
        .title-images {
            width: 430px/728px * 100%;
        }
        .title-aside-image {
            display: none;
        }
        .title-images .title-main-image figcaption {
            left: auto;
            right: 0;
        }
    }
    .about-yana-hold {
        .yana-img {
            width: 360px/728px * 100%;
        }
        .yana-col {
            width: 368px/728px * 100%;
            padding: 20px 0 40px 30px;
        }
        .circle-list {
            margin: 0 0 40px;
        }
    }
    .projects-box {
        .project {
            height: 160px;
        }
        .about-project {
            h3 {
                font-size: $fs-body;
            }
            p {
                font-size: 12px;
            }
        }
    }
    .reviews-box {
        .review-hold {
            padding-left: 20px;
        }
        .slick-prev {
            left: 275px;
        }
        .slick-next {
            left: 315px;
        }
    }
    .request-form {
        border-radius: 0;
        padding: 50px 20px;
        margin: 0 -20px;
    }
    .contact-box {
        border-radius: 0;
        margin: 0 -20px;

        .contact-col {
            width: 250px/768px * 100%;
            padding: 50px 20px;
        }
        .contact-form {
            width: 518px/768px * 100%;
            padding: 50px 20px;
        }
    }
    .modal-window {
        width: 668px;
        display: block;

        .gallery-col {
            width: 100%;
            height: auto;
        }
        .about-col {
            width: 100%;
            display: block;
            border-radius: 0 0 4px 4px;

            .quote-hold {
                padding-top: 20px;
                margin-top: 0;
            }
        }
    }
    .fade-gallery-projects {
        width: 100%;
        height: 400px;
        border-radius: 4px 4px 0 0;
        margin: 0;

        .slide img {
            height: 100%;
            object-fit: cover;
        }
    }
    .slider-gallery-projects {
        display: none;
    }
}

@media only screen and (max-width: 719px) {
    //html.modal-hidden,
    body.modal-hidden {
        overflow: hidden;
    }

    .btn {
        width: 100%;
        margin: 0;
    }
    h1 {
        font-size: 30px;
        line-height: 40px;
    }
    .title-additional {
        max-width: 100%;
        margin: 0 0 20px;
    }
    .section {
        margin: 0 0 100px;
    }
    .radio-buttons li {
        width: 100%;
        margin-right: 0;
    }
    #header {
        padding: 20px 0;
    }
    .logo {
        width: 60px;
    }
    .menu-holder {
        padding: 80px 0;
    }
    .menu-contacts {
        margin: 0 -10px 0;

        li {
            padding: 0 10px;
        }
    }
    .title-box {
        display: block;

        .title-images {
            display: block;
            width: 100%;
            margin: 0 0 10px;

            .title-main-image {
                padding: 0 0 30px;

                figcaption {
                    font-size: 12px;
                    bottom: 0;
                }
            }
        }
        .title-col {
            width: 100%;
            padding: 0;
        }
    }
    .news-box {
        .container {
            flex-direction: column;

            .poster {
                margin: 0 0 20px 0;
            }
        }
    }
    .about-yana {
        padding: 0;
        margin: 0 0 50px;

        .container {
            padding: 50px 20px;

            .about-yana-hold {
                display: block;

                .yana-img {
                    width: 100%;
                    height: 438px;
                    margin: 0 0 20px;

                    img {
                        object-fit: cover;
                    }
                }
                .yana-col {
                    width: 100%;
                    padding: 0;
                }
            }
        }
    }

    .tabs-menu {
        margin: 0 18px 10px;

        li {
            padding: 9px 16px;
            margin: 0 2px 10px;
        }
    }
    .projects-box {
        margin: 0 0 100px;
    }
    .light-gray-bg {
        padding-top: 100px;
    }
    .reviews-box {
        .slide {
            display: block;
        }
        .review-img {
            width: 100px;
            height: 100px;
            margin: 0 auto 10px;
        }
        .review-hold {
            width: 100%;
            text-align: center;
            padding: 0 0 36px;
        }
        .slick-prev {
            left: 50%;
            transform: translateX(-28px);
        }
        .slick-next {
            left: 50%;
            transform: translateX(12px);
        }
        .user-name {
            margin: 0 0 10px;
        }
    }
    .uploaded-files {
        margin: 0 0 4px;
    }
    .delimiter-form {
        display: block;

        .unit-form {
            width: calc(100% - 20px);
        }
    }
    .btn-hold {
        margin-top: 0;
    }
    .contact-box {
        display: block;

        .contact-col {
            display: block;
            width: 100%;
        }
        .contact-form {
            width: 100%;
            padding: 50px 20px 20px;
        }
    }
    #footer {
        .box {
            width: 50%;
        }
        .logo {
            order: 1;
            margin: 0 0 30px;
        }
        .socials-col {
            order: 2;
            margin: 0 0 30px;
        }
        .links-col {
            order: 3;
        }
        .contact-col {
            order: 4;
        }
    }
    .bottom-footer {
        flex-direction: column;
        align-items: center;
        text-align: center;

        .copy {
            margin: 0 0 10px;
        }
    }
    .modal-window {
        width: 100%;
        height: 100%;
        margin-top: 0;

        .close-modal {
            width: 30px;
            height: 30px;
            background: rgba(31, 31, 31, 0.8) url(../images/Close.svg) 50% 50%
                no-repeat;
            background-size: 35%;
            border-radius: 4px;
            top: 20px;
            right: 20px;
            z-index: 1;
            transition: background-color 0.1s ease-in-out;

            &:hover {
                background-color: $black-bg;
            }
        }
        .about-col {
            height: calc(100% - 300px);
            overflow-y: auto;
            border-radius: 0;
        }
    }
    .fade-gallery-projects {
        height: 300px;
        border-radius: 0;

        .slick-arrow {
            width: 30px;
            height: 30px;
            background-size: 25%;
        }
    }
    #contact-section {
        margin: 0 0 50px;
    }

    .successful-window {
        width: 320px;
        padding: 50px 20px;
    }
    .error-page .btn {
        width: inherit;
    }
}
