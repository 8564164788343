@import 'variables.scss';


.unit-form {
    position: relative;
    z-index: 1;
    margin: 0 0 20px;

    .label-form {
        opacity: .6;
        line-height: 16px;
        font-weight: normal;
        position: absolute;
        z-index: -1;
        top: 18px;
        left: 0;
        transition: all .1s ease-in-out;
    }
    input:focus,
    textarea:focus {
        box-shadow: 0px -2px 0px 0px rgba(35, 35, 35, 1) inset;

        + label {
            opacity: 1;
        }
    }
    input:focus + label,
    input:valid + label,
    textarea:focus + label,
    textarea:valid + label {
        top: 2px;
        font-size: 12px;
        line-height: 14px;
    }
    &.error-form {

        .label-form {
            color: $error-color;
            opacity: 1;
        }
        input,
        textarea {
            box-shadow: 0px -2px 0px 0px $error-color inset;
            padding-right: 16px;
        }
        .error-form-text {
            display: block;
        }
        &:before {
            content: '';
            background: url(../images/ic-error.png) 50% 50% no-repeat;
            background-size: contain;
            width: 16px;
            height: 16px;
            position: absolute;
            top: 24px;
            right: 0;
        }
    }
    .error-form-text {
        display: none;
        font-size: 12px;
        line-height: 14px;
        color: $error-color;
        margin-top: 8px;
        position: absolute;
        top: 100%;
        left: 0;
    }
}

.delimiter-form {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    .unit-form {
        width: calc(50% - 20px);
        margin: 0 10px 20px;
        box-sizing: border-box;
    }
}
input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="phone"],
textarea {
    width: 100%;
    height: 52px;
    font-size: $fs-body;
    line-height: 16px;
    font-family: $body-font;
    color: $text-color;
    background: none;
    border: none;
    box-shadow: 0px -1px 0px 0px rgba(151, 151, 151, .2) inset;
    outline: 0 none;
    padding: 24px 0 12px;
    margin: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    box-sizing: border-box;
    transition: box-shadow .1s ease-in-out;
}
textarea {
    width: 100% !important;
    max-width: 100% !important;
    min-height: 72px;
    height: 72px;
}
.btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 48px;
    font-size: $fs-body;
    line-height: $lh-body;
    font-family: $title-font;
    font-weight: 500;
    color: $white-color;
    background: $black-bg;
    border: 0 none;
    outline: 0 none;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 0 20px;
    position: relative;
    padding: 0 50px;
    box-sizing: border-box;
    -webkit-transition: all .1s ease-in-out;
    -moz-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;

    &:hover {
        background-color: $text-color;
        text-decoration: none;
    }
    &.border-btn {
        color: $text-color;
        background-color: $body-bg;
        border: 1px solid $text-color;
        padding: 0 60px;

        &:hover {
            color: $white-color;
            background-color: $text-color;
        }
    }
}
.radio-buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    white-space: nowrap;
    line-height: 16px;
    font-weight: normal;

    li {
        margin: 0 40px 20px 0;
    }
    input {
        position: absolute;
        left: -9999px;

        &:checked + label:before {
            border-width: 4px;
        }
    }
    label {
        display: flex;
        align-items: center;
        cursor: pointer;

        &:before {
            content: '';
            width: 16px;
            min-width: 16px;
            height: 16px;
            border: 1px solid $black-bg;
            border-radius: 50%;
            margin: -1px 10px 0 0;
            box-sizing: border-box;
            transition: border-width .1s ease-in-out;
        }
    }
    .error-form label:before {
        border-color: $error-color;
    }
}
.upload-hold {
    margin: 0 0 20px;

    .title-upload {
        display: block;
        font-size: 12px;
        line-height: 14px;
        font-weight: normal;
        opacity: .6;
        margin: 0 0 10px;
    }
    input[type="file"] {
        display: none;
    }
    .upload-label {
        height: 90px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        background-color: $light-gray-bg;
        border: 1px dashed rgba(35,35,35,0.2);
        border-radius: 4px;
        box-sizing: border-box;
        cursor: pointer;
        transition: all .1s ease-in-out;

        &:hover {
            background-color: $body-bg;
            border-color: $black-bg;
        }
        .upload-icon {
            width: 24px;
            height: 24px;
            background: url(../images/Photo.svg) 50% 50% no-repeat;
            margin: 0 0 10px;
        }
    }
}
.uploaded-files {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 24px;

    .file {
        width: 98px;
        height: 98px;
        position: relative;
        margin: 0 16px 16px 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 4px;
        }
    }
    .close-file {
        width: 24px;
        height: 24px;
        background: $black-bg url(../images/Close.svg) 50% 50% no-repeat;
        border: 1px solid $body-bg;
        border-radius: 50%;
        outline: none;
        padding: 0;
        position: absolute;
        top: -4px;
        right: -4px;
        box-sizing: border-box;
        cursor: pointer;
    }
    .download-row {
        overflow: hidden;
        height: 6px;
        background-color: rgba(255,255,255,.2);
        border-radius: 4px;
        position: absolute;
        top: 50%;
        left: 10px;
        right: 10px;
        transform: translateY(-50%);

        .paint-row {
            border-radius: 4px;
            background-color: $body-bg;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
        }
    }
}