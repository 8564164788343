@import 'variables.scss';


.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    .slide {outline: none;}
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }
    &.dragging {cursor: pointer;}
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
    .slick-loading & {
        visibility: hidden;
    }
    > p {display: none !important;}
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }
    display: none;

    &.dragging img {
        pointer-events: none;
    }
    .slick-initialized & {
        display: block;
    }
    .slick-loading & {
        visibility: hidden;
    }
    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

.reviews-box {
    overflow: hidden;

    .slide {
        display: flex;
        align-items: center;
    }
    .review-img {
        width: 255px;
        height: 235px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .review-hold {
        width: calc(100% - 255px);
        padding: 0 0 36px 50px;
        box-sizing: border-box;
    }
    .user-name {
        display: block;
        font-weight: 500;
        margin: 0 0 20px;
    }
    blockquote {
        margin: 0;
    }
    .slick-arrow {
        width: 16px;
        height: 16px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-color: inherit;
        background-size: 40%;
        text-indent: -9999px;
        overflow: hidden;
        border: none;
        outline: none;
        padding: 0;
        cursor: pointer;
        position: absolute;
        bottom: 0;
        z-index: 10;
    }
    .slick-prev {
        background-image: url(../images/Chevron-Left.svg);
        left: 305px;
    }
    .slick-next {
        background-image: url(../images/Chevron-Right.svg);
        left: 345px;
    }
}
.reviews-slider {
    position: relative;
}
.projects-box {
    width: 100vw;
    padding: 0 0 58px;
    margin: 0 0 150px;

    .project {
        display: block;
        height: 300px;
        overflow: hidden;
        position: relative;
        margin: 0 1px 1px 0;
        cursor: pointer;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &:hover .about-project {
            opacity: 1;
            visibility: visible;
            transform: scaleY(1);
        }
    }
    .about-project {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: $white-color;
        background-color: rgba(0,0,0,0.6);
        padding: 8.426%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-sizing: border-box;
        opacity: 0;
        visibility: hidden;
        transform: scaleY(.5);
        transition: all .2s ease-in-out;

        h3 {
            font-weight: normal;
            margin: 0;
        }
        p {
            line-height: 16px;
            opacity: .6;
            margin: 0;
        }
    }
    .slick-arrow {
        width: 44px;
        height: 38px;
        text-indent: -9999px;
        overflow: hidden;
        background: $black-bg url(../images/Chevron-Left-white.svg) 50% 50% no-repeat;
        border: 1px solid $white-color;
        border-top: none;
        border-bottom: none;
        outline: none;
        border-radius: 2px 0 0 2px;
        padding: 0;
        cursor: pointer;
        position: absolute;
        bottom: 0;
        right: 50%;

        &.slick-next {
            transform: scaleX(-1) translateX(-100%);
        }
        &.slick-disabled {
            opacity: .6;
            cursor: default;
        }
    }
}
.modal-window {
    width: 1110px;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: -9999px;
    top: -9999px;
    z-index: 1000;
    margin-top: 40px;

    .close-modal {
        width: 24px;
        height: 24px;
        background: url(../images/Close.svg) 50% 50% no-repeat;
        background-size: 65%;
        cursor: pointer;
        position: absolute;
        top: -40px;
        right: -115px;
    }
    .gallery-col {
        width: 760px/1110px*100%;
        height: 830px;
        display: flex;
        justify-content: space-between;
    }
    .about-col {
        width: 340px/1110px*100%;
        display: flex;
        flex-direction: column;
        background-color: $body-bg;
        border-radius: 4px;
        padding: 30px;
        box-sizing: border-box;

        .medium-title {
            margin: 0 0 20px;
        }
        .quote-hold {
            margin-top: auto;
        }
        blockquote {
            font-style: italic;
            margin: 0 0 20px;

            &:before {
                content: '';
                display: block;
                background: url(../images/ic-quote.svg) 50% 50% no-repeat;
                background-size: contain;
                width: 20px;
                height: 15px;
                margin: 0 0 20px;
            }
        }
        .user-name {
            display: block;
            font-weight: 500;
        }
    }
    &.gallery-fixed .slider-gallery-projects .slick-track {
        transform: none !important;
    }
}
.fade-gallery-projects {
    width: calc(100% - 105px);
    height: 100%;
    overflow: hidden;
    border-radius: 4px;

    .slick-list,
    .slick-track,
    .slide {
        height: 100%;
    }
    .slide {
        background-color: $body-bg;
        display: flex;
        align-items: center;

        img {
            width: 100%;
            height: auto;
            object-fit: contain;
        }
    }
    .slick-arrow {
        width: 44px;
        height: 44px;
        background-color: rgba(31,31,31,.8);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        border: none;
        outline: none;
        border-radius: 4px;
        text-indent: -9999px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        cursor: pointer;
        transition: background-color .1s ease-in-out;

        &:hover {background-color: $black-bg;}
    }
    .slick-prev {
        background-image: url(../images/Chevron-Left-white.svg);
        left: 20px;
    }
    .slick-next {
        background-image: url(../images/Chevron-Right-white.svg);
        right: 20px;
    }
}
.slider-gallery-projects {
    width: 95px;

    .slick-list {
        height: 100% !important;
    }
    .slide {
        width: 100%;
        height: 93px;
        border-radius: 4px;
        overflow: hidden;
        position: relative;
        margin: 0 0 10px;

        &:before {
            content: '';
            border-width: 0;
            border-style: solid;
            border-color: $body-bg;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transition: border-width .1s linear;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &.slick-current:before {border-width: 4px;}
    }
}