@import 'variables.scss';

html,
body {
    height: 100%;
    margin: 0;
}
html * {
    max-height: 999999px;
}

body {
    min-width: 320px;
    font-size: $fs-body;
    line-height: $lh-body;
    font-family: $body-font;
    font-weight: 300;
    color: $text-color;
    background: $body-bg;
    margin: 0;
    -webkit-text-size-adjust: 100%;
}

/*	   general reset   	*/
* {
    scroll-behavior: smooth;
}

form,
fieldset {
    margin: 0;
    padding: 0;
    border-style: none;
}
img {
    border-style: none;
    vertical-align: top;
}
ul li {
    vertical-align: top;
}

input,
select,
textarea {
    vertical-align: middle;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a {
    color: $text-color;
    text-decoration: none;

    &:focus,
    &:hover {
        text-decoration: underline;
        outline: 0 none;
    }
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

figure {
    margin: 0;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

/*	   wrapper   	*/
#wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    min-height: 100%;
}
.w1 {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    -webkit-flex-basis: auto;
    flex-basis: auto;
    z-index: 1;
    min-height: 1px;
}
#footer {
    -webkit-flex-grow: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    -webkit-flex-basis: auto;
    flex-basis: auto;
}
.container {
    max-width: 1110px;
    margin: 0 auto;
    padding: 0 20px;
}
p {
    margin: 0 0 20px;
}

h1 {
    display: block;
    font-size: $fs-h1;
    line-height: $lh-h1;
    font-family: $title-font;
    font-weight: 700;
    white-space: nowrap;
    margin: 0 0 20px;
}
h2 {
    display: block;
    font-size: $fs-h2;
    line-height: $fs-h2;
    font-family: $rear-title-font;
    font-weight: 700;
    color: $rear-title-color;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    margin: 0 0 40px;

    .medium-title {
        color: $text-color;
        text-align: center;
        margin: 0;
        position: absolute;
        top: 36px/100px * 100%;
        left: 50%;
        transform: translateX(-50%);
    }
}
.medium-title {
    display: block;
    font-size: $fs-medium;
    line-height: $lh-medium;
    font-family: $title-font;
    font-weight: 700;
    text-transform: none;
    text-align: left;
    margin: 0 0 10px;
}
h3 {
    display: block;
    font-size: $fs-small-title;
    line-height: $lh-body;
    font-family: $body-font;
    font-weight: 500;
    margin: 0 0 20px;
}
.title-additional {
    display: block;
    max-width: 540px;
    text-align: center;
    margin: 0 auto 40px;
}
strong,
b {
    font-weight: 700;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
dl {
    margin: 0;

    dt {
        display: inline;
        font-weight: 500;
    }
    dd {
        display: inline;
        margin: 0;
    }
    &:last-of-type {
        margin: 0 0 20px;
    }
}
.circle-list-title {
    display: block;
}
.circle-list li:before {
    content: '';
    display: inline-block;
    vertical-align: top;
    width: 3px;
    height: 3px;
    background-color: $body-bg;
    border-radius: 50%;
    opacity: 0.5;
    margin: 8px 4px 0 0;
}
.socials-links {
    display: flex;
    align-items: center;

    a {
        display: block;
        width: 20px;
        min-width: 20px;
        height: 20px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        opacity: 0.88;
        margin-left: 20px;
        transition: all 0.1s ease-in-out;

        &:hover {
            opacity: 1;
            margin-top: -4px;
        }
    }
    /*.twitter-link {
		background-image: url(../images/Twitter.svg);
	}*/
    .instagram-link {
        background-image: url(../images/Instagram.svg);
    }
    .facebook-link {
        background-image: url(../images/Facebook.svg);
    }
    &.white-links {
        /*.twitter-link {
            background-image: url(../images/Twitter-white.svg);
        }*/
        .instagram-link {
            background-image: url(../images/Instagram-white.svg);
        }
        .facebook-link {
            background-image: url(../images/Facebook-white.svg);
        }
    }
}
.contact-list {
    li {
        display: flex;
        margin: 0 0 10px;
    }
    a,
    span {
        display: inline-flex;
        align-items: center;
        color: rgba(255, 255, 255, 0.5);
        transition: color 0.1s ease-in-out;

        &:before {
            content: '';
            width: 20px;
            min-width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            margin-right: 8px;
        }
        &:hover {
            color: $white-color;
            text-decoration: none;
        }
    }
    .yana-text {
        &:before {
            background-image: url(../images/Art.svg);
        }
        &:hover {
            color: rgba(255, 255, 255, 0.5);
        }
    }
    .phone-link:before {
        background-image: url(../images/Phone.svg);
    }
    .email-link:before {
        background-image: url(../images/Email.svg);
    }
}
figure {
    overflow: hidden;
    border-radius: 4px;

    img {
        display: block;
        width: 100%;
        height: auto;
    }
}
.light-gray-bg {
    overflow: hidden;
    background-color: $light-gray-bg;
    padding-top: 150px;
}
.section {
    margin: 0 0 150px;
}

/*	   main   	*/
#main {
    width: 100%;
}

.title-box {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    position: relative;
    z-index: 2;
    margin: 0 0 50px;

    .title-images {
        display: flex;
        width: 610px/1110px * 100%;

        .title-main-image {
            width: 100%;
            overflow: visible;
            position: relative;

            img {
                display: block;
                width: 100%;
                height: auto;
                border-radius: 4px;
            }
            figcaption {
                text-transform: uppercase;
                font-weight: 400;
                white-space: nowrap;
                background: url(../images/img-paint-brush.svg) 50% 50% no-repeat;
                background-size: contain;
                padding: 40px 14px 32px 80px;
                position: absolute;
                bottom: -32px;
                left: -95px;
            }
        }
        .title-aside-image {
            width: 610px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 100%;
            margin-left: 10px;

            img {
                display: block;
                width: 100%;
                height: auto;
            }
        }
    }
    .title-col {
        width: 500px/1110px * 100%;
        font-size: 16px;
        padding: 2.524% 50px/1110px * 100% 0 0;
        box-sizing: border-box;
        position: relative;
        z-index: 2;
    }
}
.news-box {
    background-color: $light-gray-bg;

    .container {
        padding: 50px 20px;
        display: flex;
        max-width: 1110px;
        margin: 0 auto;

        .poster {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            background: url(../images/img-news-01.png) 50% 50% no-repeat;
            background-size: cover;
            margin: 0 20px 0 0;
            min-height: 176px;
            min-width: 176px;
        }

        .details {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .news-date {
                opacity: 0.6;
                font-size: 12px;
                line-height: 14px;
                margin-bottom: 10px;
            }

            .title {
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0;
                margin-bottom: 6px;
            }

            img {
                height: 16px;
                width: 110px;
                margin-bottom: 16px;
            }

            a {
                font: 14px/18px $title-font;
                font-weight: 500;
                text-decoration: none;

                &:after {
                    content: '';
                    position: absolute;
                    background: url(../images/ic-read-now.svg) 50% 50% no-repeat;
                    margin-left: 6px;
                    min-height: 16px;
                    min-width: 18px;
                    background-size: 12px 9px;
                }

                &:hover {
                    opacity: 0.6;

                }
            }
        }
    }
}
.about-yana {
    color: $white-color;
    background-color: $black-bg;
    position: relative;
    z-index: 1;
    padding: 150px 0;
    margin: 0 0 150px;

    .contact-list a {
        color: $white-color;

        &:hover {
            text-decoration: underline;
        }
    }
}
.about-yana-hold {
    display: flex;
    align-items: center;

    .yana-img {
        display: flex;
        width: 520px/1110px * 100%;

        img {
            object-fit: contain;
        }
    }
    .yana-col {
        width: 590px/1110px * 100%;
        padding: 10px 0 0 50px/1110px * 100%;
        box-sizing: border-box;
    }
    .medium-title {
        color: $white-color;
    }
    .circle-list {
        margin: 0 0 50px;
    }
}
.request-form {
    background-color: $body-bg;
    border-radius: 4px;
    padding: 50px 50px/1110px * 100%;
}
.request-hold {
    overflow: hidden;
    margin: 0 0 40px;

    h3 mark {
        font-weight: normal;
        background: none;
    }
}
.btn-hold {
    text-align: right;
    margin-top: -20px;

    .btn {
        margin: 0;
    }
}
.contact-box {
    display: flex;
    background-color: $body-bg;
    border-radius: 4px;
    overflow: hidden;

    .contact-col {
        width: 350px/1110px * 100%;
        display: flex;
        flex-direction: column;
        background-color: $black-bg;
        padding: 50px 50px/1110px * 100%;
        box-sizing: border-box;

        h3 {
            color: $white-color;
        }
    }
    .contact-list {
        margin: 0 0 57px;
    }

    .socials-links {
        margin-top: auto;

        a {
            margin-left: 0;
            margin-right: 20px;
        }
    }
    .contact-form {
        width: 760px/1110px * 100%;
        padding: 50px 50px/1110px * 100%;
        box-sizing: border-box;
    }
    .unit-form {
        margin-bottom: 40px;
    }

    .radio-buttons {
        margin: 0 0 20px;
    }
}
.successful-window {
    width: 500px;
    background-color: $body-bg;
    border-radius: 4px;
    text-align: center;
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 1000;
    padding: 50px;
    box-sizing: border-box;

    .medium-title {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 64px;
        background: url(../images/brush.svg) 50% 50% no-repeat;
        background-size: contain;
        margin: 0 0 30px;
    }
    .btn {
        margin: 10px auto 0;
    }
}
.hide {
    display: none !important;
}

/*	   404  page   	*/
.error-page {
    font-size: $fs-small-title;
    font-weight: 500;
    color: $white-color;
    text-align: center;

    #header,
    #footer {
        display: none;
    }
    #main {
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .error-box {
        position: relative;
        padding: 0 50px;
        z-index: 1;

        &:before {
            content: '';
            background: url(../images/img-brush.svg) 50% 50% no-repeat;
            background-size: contain;
            width: 1208px;
            height: 933px;
            position: absolute;
            right: -100px;
            bottom: -212px;
            z-index: -1;
        }
    }
    p {
        margin: 0 0 10px;
    }
    .number-error {
        display: block;
        font-size: 100px;
        line-height: 100px;
        font-family: $rear-title-font;
        font-weight: 700;
        color: $text-color;
        margin: 0 0 20px;
    }
    .btn {
        margin: 10px 0 0;
    }
}
