@import 'variables.scss';


/*	   header   	*/
#header {
	width: 100%;
	padding: 20px 0 40px;
}
.header-hold {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.socials-links {z-index: 101;}
}
.logo {
    width: 80px;
	position: relative;
	z-index: 101;

    img {
        display: block;
        width: 100%;
        height: auto;
    }
	.white-logo {display: none;}
}

/*	   navigation   	*/
input[id="check-menu"] {display:none;}

.toogle-menu {
	display: none;
	width: 24px;
	height: 24px;
	background: url(../images/Burger.svg) 50% 50% no-repeat;
	background-size: contain;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
	position: relative;
	z-index: 101;
}
.menu {
	display: flex;
	align-items: center;
	
	> li {

		> a {
			display: block;
			font-weight: normal;
            color: $text-color;
			text-transform: uppercase;
			position: relative;
			z-index: 2;
            padding: 15px;
			transition: all .2s ease-in-out;

			&:hover {text-decoration: underline;}
		}
	}
}
.menu-contacts {
    display: none;
    justify-content: center;
    font-size: 12px;
	margin: 0 -20px;
	position: absolute;
	bottom: 20px;
	left: 0;
	right: 0;

    li {
        padding: 0 20px;
    }
    a,
	span {
		display: block;
        color: $white-color;
        opacity: .6;
        transition: opacity .1s ease-in-out;

        &:hover {opacity: 1;}
    }
}
/*	   end navigation   	*/